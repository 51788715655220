import React,{lazy,Suspense} from "react";
import { Route, Routes } from 'react-router-dom'
import Loader from "./Loader";
import PrivateComponent from "./PrivateComponent";
import withRouter from "./withRouter";
import Error from "./Error";
import ErrorBoundary from "./ErrorBoundary";
import SignInComponent from "./SignInComponent";

const DailyNews = lazy(()=> import('../Components/DailyNews'));
const CrimeArticle = lazy(()=> import('../Components/CrimeArticle'));
const ArticleSubmitForm = lazy(()=> import('../Components/ArticleSubmitForm'));
const EconomicArticle = lazy(()=> import('../Components/EconomicArticles'));
const EducationArticles = lazy(()=> import('../Components/EducationArticles'));
const SocialArticles = lazy(()=> import('../Components/SocialArticles'));

// const SignInComp = lazy(()=> import('../../Pages/signIn/signInContainer'));


const Routers = ()=>{
  
     return (
          <ErrorBoundary>
          <Suspense fallback={<Loader />}>
          <Routes>
          <Route exact path="/" element={<DailyNews />} />
          <Route path="/Ecarticles" element={<EconomicArticle />} />
          <Route path="/Crarticles" element={<CrimeArticle />} />
          <Route path="/Edarticles" element={<EducationArticles />} />
          <Route path="/Soarticles" element={<SocialArticles />} />
          <Route path="/signIn" element={<SignInComponent />} />

          {/* <Route element={<PrivateComponent />}> */}
          <Route path="/dArticle" element={<ArticleSubmitForm />} />
          
          {/* </Route> */}

          <Route path="/*" element={<Error /> } />
          </Routes>
          </Suspense>
          </ErrorBoundary>

)};

export default withRouter(Routers);