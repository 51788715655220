import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function HeaderPage() {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark" >
        <Container className="container-fluid">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand href="/" style={{fontWeight:'800',fontSize:'xx-large'}}><span style={{color:'red'}}>D</span><span style={{color:'blue'}}>ARTICLE</span></Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          <Nav.Link href="/">Daily NEWS</Nav.Link>
          <Nav.Link href="/Edarticles">Education </Nav.Link>
            <Nav.Link href="/Soarticles">Social </Nav.Link>
            <Nav.Link href="/Ecarticles">Economic </Nav.Link>
            <Nav.Link href="/Crarticles">Crime </Nav.Link>
            
          </Nav>
          </Navbar.Collapse> 
        </Container>
      </Navbar>
      
    </>
  );
}

export default HeaderPage;