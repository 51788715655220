import { Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FooterContainer } from '../Components/DarticlesStyle';

function FooterPage() {
  return (
    <FooterContainer className='footers'>
      <Navbar collapseOnSelect expand="lg" bg="dark" data-bs-theme="dark" >
        <Container className="container-fluid d-flex mt-1 justify-content-center">
          
          <Row className='p-2' >
        <div className='col-1'></div>
        <div className='col-10 text-light policyA'>
        © 2024 Copyright: <a className='text-light' target="_blank" title="T&C PDF" href='/'>
          Term & Conditions
        </a> &nbsp;
        <a className='text-light' target="_blank" title="privacyPolicy PDF" href='/'>
          Privacy Policy
        </a>&nbsp;
        <a className='text-light ml-2 CTA' href="/" target="_blank" title="refundPolicy PDF" >Refund Policy</a>&nbsp;
        <a className='text-light ml-2 CTA' href="/" target="_blank" title="shopDelivery PDF" >Delivery Policy</a>
        </div>
        <div className='col-1'></div>
        </Row>
        </Container>
      </Navbar>
      
    </FooterContainer>
  );
}

export default FooterPage;