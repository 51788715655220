import './App.css';
import HeaderPage from './Common/HeaderPage';
import Routers from './Common/Routers';
import { BrowserRouter } from 'react-router-dom';
import { AppContainer } from './Components/DarticlesStyle';
import FooterPage from './Common/Footer';

function App() {
  return (<BrowserRouter>
    <AppContainer>
    <HeaderPage />
    <Routers />
    <FooterPage/>
    </AppContainer>
    </BrowserRouter>
  );
}

export default App;
