import styled from "styled-components";

export const AppContainer = styled.div`
 background-image:url('../webPageBackground.jpg');
  // background-size: cover;
  background-repeat: repeat;
`;

export const FooterContainer = styled.div`
.policyA{
    width: 50rem;
    display: flex;
    
    justify-content: space-between;
     @media only screen and (max-width: 600px) {width:22rem;
     }
    
}
      @media only screen and (max-width: 600px) {
     margin-top: 50.5%;
     }
      margin-top: 2.5%;
`;
export const DailyNewsContainer = styled.div`
.NewsCard{
width:30rem;
    text-align: -webkit-auto;
    margin-bottom: 20px;
   @media only screen and (max-width: 600px) {width:22rem;}
}
    .CardDiv{
    text-align: -webkit-center;
    margin: 25px;
    }
    .BtnGrp{
font-family: monospace;    }
.NewsFeed{
display: flex;
    justify-content: center;
flex-flow: wrap;
}
`;
export const DArticleWritingDiv =styled.div`
padding:5%;
  .Size45{
font-size:45%;
}
.signUpRow{
justify-content: center !important;
    display: flex !important;
    background-color: azure !important;
}   
`;