import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getCookie } from "./Helpers";

const PrivateComponent = () => {
    const auth = getCookie('IsLoggined');
    console.log(auth,'77')
    // const userIde = getCookie('iValueOne');
    // const fnam = getCookie('iValueTwo');
    return auth ? <Outlet /> : <Navigate to="/signIn" />
}

export default PrivateComponent;