import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";

import {
  Button,
  Row,
  Col,
  Form,
  FormControl,
} from "react-bootstrap";
import styled from "styled-components";
import { setCookie } from "./Helpers";
import Loader from "./Loader";


const ContainerDiv = styled.div`
  padding:5%;
  .Size45{
font-size:45%;
}
.signInRow{
justify-content: center !important;
    display: flex !important;
    background-color: azure !important;
}   
  `

const SignInComponent = (props) => {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState({
    username: "",
    password: "",
  });

  const [isDisable, setDisable] = useState(true);
  const [isUnAuth, setIsUnAuth] = useState(false);
  const [loginData, setLoginData]=useState(false)
  const [smShow, setSmShow] = useState(false);
  const [userNamevalue, setUsernameInputValue] = useState();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    console.log(loginData?.data?.email,'signin',loginData?.authed);
    if(loginData?.errorCode==='00'){
      setCookie('IsLoggined',true,1)
      setIsUnAuth(false);
      setCookie('userId',loginData?.data?.email,1);
      setCookie('authToken',loginData?.authed,1);
      navigate("/dArticle");
    }else if(loginData?.errorCode==='01'){
      setIsUnAuth(true)
    }
//     const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
//   const isValidEmail = emailRegex.test(userData?.data?.username);
//   const payload = isValidEmail ? {
//     "password": userData?.data?.password,
//     "email": userData?.data?.username,
//   } : {
//     "password": userData?.data?.password,
//     "phone": userData?.data?.username
//   }
// const url ="https://fireapicalls.govindmukund.com/usersignin";
//     axios.get(url,payload)
//         .then(response => {
//             console.log(response, 'dd', response.data?.results)
//             setNewsdata(response.data?.results);
//         })
//         .catch(error => {
//             console.error(error);
//         });
}, [loginData])



  const navigate = useNavigate();
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  };
  // const deleteAccountForSignUp = () => {
  //   setSmShow(true);
  // }

 

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please enter email/phone.";
          } else setIsUnAuth(false)
          break;
        case "password":
          if (!value) {
            stateObj[name] = "Please enter Password.";
          } else { setDisable(false); setIsUnAuth(false) };
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  const onLoginClick = () => {
    setIsLoading(true);
    const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    const isValidEmail = emailRegex.test(input.username);
    const payload = isValidEmail ? {
      "password": input?.password,
      "email": input?.username,
    } : {
      "password": input?.password,
      "phone": input?.username
    }
  const url ="https://apitemp.govindmukund.com/usersignin";
      axios.post(url,payload)
          .then(response => {
              // console.log(response, 'dd', response.data?.results);
              setLoginData(response?.data);
              setIsLoading(false);
          })
          .catch(error => {
              console.error(error);
          });
          ////
    console.log(input);
    // if(input?.username==="f"&& input?.password==="12345"){
    //   setCookie('IsLoggined',true,1)
    //   setLoginData(true)
    // }else {
    //   setIsUnAuth(true)
    // }
  };
  // useEffect(() => {
  //   if(loginData) { navigate("/dArticle");alert(loginData) } else { setIsUnAuth(true) }
  // }, [loginData]);
  return (
    <ContainerDiv>
      {isLoading && <Loader />  }
      <Row className="signInRow">
        <Col md="4">
          <h1>Login</h1>
          <Form style={{ marginBottom: "5%" }}>
            <Form.Group controlId="usernameId">
              <Form.Label>User name</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Enter email/mobile"
                value={input.username}
                onBlur={validateInput}
                onChange={onInputChange}
              />
              <FormControl.Feedback type="invalid"></FormControl.Feedback>
              {error.username && (
                <span className="text-danger">{error.username}</span>
              )}
            </Form.Group>

            <Form.Group controlId="passwordId">
              <Form.Label>Your password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Enter password"
                value={input.password}
                onBlur={validateInput}
                onChange={onInputChange}
              />
              <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
              {isUnAuth && (
                <span className="text-danger">Invalid credentials</span>
              )}
            </Form.Group>
          </Form>
          <Button color="primary" disabled={isDisable} onClick={onLoginClick}>
            Login
          </Button>
          <p className="mt-2">
            Want to read news / articles ? <Link href="/"><span style={{color:'red'}}>D</span><span style={{color:'blue'}}>ARTICLE</span></Link>
          </p>
        </Col>
      </Row>
      {/* <Modal
        // size="lg"
        show={smShow}
        centered={true}
        onHide={() => { setSmShow(false) }}
        aria-labelledby="message-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Enter your Email / Phone.
          </Modal.Title>
        </Modal.Header>
        {props?.code && <Modal.Body>{props?.code === '00' ? navigate("/signUp") : <span className="text-danger">{props?.user}</span>}</Modal.Body>}
        <input
          type="text"
          name="username"
          placeholder="Enter email/mobile"
          style={{ border: 'none', margin: '0 3% 0 3%', height: '49px' }}
          onChange={(e) => setUsernameInputValue(e.target.value)}
        />
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { props.deleteUserAcount(userNamevalue) }}>
            SignUp
          </Button>
        </Modal.Footer>

      </Modal> */}

    </ContainerDiv>
  );
};

export default SignInComponent;
