import React from "react";
import Spinner from 'react-bootstrap/Spinner';
import styled from "styled-components";

const SpinnerDiv=styled.div`
color:red;
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: #01223770;
    z-index: 9999;
    padding-top: 20%;
    text-align: center;
`;

const Loader =()=>{

    return (<SpinnerDiv>
    <Spinner animation="grow" size="lg" />
    </SpinnerDiv>)
}

export default Loader;